import { useField } from "formik";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement } from "react";
import { Item } from "react-stately";

import Select from "../../../../../core/ui/molecules/select/Select";

interface SalutationSelectProps {
  name: string;
  label: string;
  adoptionClassName?: string;
}

interface GenderOption {
  value: string;
  name: string;
}

const SalutationSelect = ({
  name,
  label,
  adoptionClassName,
}: SalutationSelectProps): ReactElement => {
  const { t } = useTranslation();
  const [_field, meta, helpers] = useField<string>(name);

  const { setValue } = helpers;
  const { value } = meta;

  const handleSelect = (gender: string) => {
    setValue(gender, true);
  };

  const genderOptions: GenderOption[] = [
    { value: "male", name: t("pet_parent.personal_data.pet_parent_salutation.field.male") },
    {
      value: "female",
      name: t("pet_parent.personal_data.pet_parent_salutation.field.female"),
    },
    {
      value: "other",
      name: t("pet_parent.personal_data.pet_parent_salutation.field.other"),
    },
  ];

  return (
    <Select
      label={label}
      adoptionClassName={adoptionClassName}
      items={genderOptions}
      selectedKey={value}
      onSelectionChange={(selected) => handleSelect(selected as string)}
    >
      {(item: GenderOption) => (
        <Item key={item.value} textValue={item.name}>
          {item.name}
        </Item>
      )}
    </Select>
  );
};

export default SalutationSelect;
